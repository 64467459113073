<template>
  <div class="maplab">
    <div id="graph"></div>
    <component :x="x" :y="y" @UpdateDevice="UpdateDevice" @removeNode="removeNode" @SetDevice="SetDevice" @removeDevice="removeDevice" :isupdate.sync="isupdate" :is="ismenu ? `rightmenu` : ''"></component>
    <Dialog v-if="!isSet" :title="DialogTitle" ref="Dialog" @confirm="confirm" :type="type" :content="content" @clearContent="clearContent" />
    <Dialog v-else :title="DialogTitle" ref="SetDialog" @confirm="confirm" @clearContent="clearContent">
      <div class="slot-dialog" style="padding-left: 40px">
        <el-form :inline="true" :model="from" class="demo-form-inline">
          <el-form-item label="选择设备">
            <el-select v-model="from.deviceId" placeholder="请选择设备">
              <el-option v-for="item in DeviceData" :key="item.deviceId" :label="item.deviceName" :value="item.deviceId"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import { Graph } from '@antv/x6';
import rightmenu from './ContextMenu/index.vue';
import Dialog from '../dialog';
import event from './event';
import Base64 from '../../base64.js';
export default {
  components: { rightmenu, Dialog },
  props: ['MapData'],
  data() {
    return {
      graph: null,
      nodeData: {
        // 节点
        nodes: []
      },
      // 菜单展示语法
      ismenu: false,
      // 菜单坐标
      x: 0,
      y: 0,
      // 当前点击Node
      node: null,
      // 当前node是否已绑定设备 (右键菜单)
      isupdate: false,
      // dialog提示内容
      content: '',
      // dialog icon类型
      type: '',
      // 是否为绑定设备
      isSet: false,
      from: { deviceId: '' },
      DialogTitle: '',
      // 设备列表
      DeviceData: [],
      // 已绑定设备列表
      setDeviceId: []
    };
  },
  mounted() {
    if (this.MapData) {
      this.init();
    }
  },
  watch: {
    MapData() {
      if (this.MapData.mapContent) {
        // return false;
        this.nodeData.nodes = JSON.parse(Base64.decode(this.MapData.mapContent));
      }
      this.init();
      const NodeDetail = this.graph.getNodes();
      NodeDetail.forEach(item => {
        this.nodeData.nodes.forEach(ele => {
          if (item.id === ele.id) {
            item.deviceId = ele.deviceId;
            this.setDeviceId.push(ele.deviceId);
          }
        });
      });
    },
    deep: true
  },
  methods: {
    // 初始化画布(并挂载地图事件)
    async init() {
      let backgroundUrl = '';
      if (this.MapData.backgrAddress) {
        backgroundUrl = process.env.VUE_APP_API_BASE_URL + this.MapData.backgrAddress;
      }
      this.graph = new Graph({
        container: document.getElementById('graph'),
        width: 1920,
        height: 685,
        // 键盘事件开启
        keyboard: {
          enabled: true
        },
        // 历史记录
        history: true,
        // 背景图片
        background: {
          image: backgroundUrl,
          position: 'center',
          size: 'contain',
          repeat: 'no-repeat'
        },
        mousewheel: {
          enabled: true,
          modifiers: ['ctrl', 'meta']
        },
        // 对齐线
        snapline: true,
        // 缩放节点
        resizing: true
      });
      this.graph.fromJSON(this.nodeData);
      event.rightClick(this);
      event.z(this.graph);
      event.removeMenu(this);
      const res = await this.$apis.Map3dConfig.get({ currentPageSize: 999 });
      this.DeviceData = res.items;
    },
    SetDevice() {
      this.DialogTitle = '绑定设备';
      this.isSet = true;
      this.$nextTick(() => {
        this.$refs.SetDialog.show();
      });
    },
    // 清空dialog提示
    clearContent() {
      this.DialogTitle = '';
      this.content = '';
      this.from.deviceId = '';
      this.isSet = false;
      this.type = '';
    },
    // 重置
    clearAll() {
      this.nodeData = { nodes: [] };
      this.setDeviceId = [];
    },
    // 添加Node
    addNode() {
      event.add(this.graph);
    },
    // dialog 确定
    confirm() {
      // 是否为绑定设备
      if (this.isSet) {
        this.DialogTitle = '';
        this.content = '';
        this.type = '';
        // 判断是否有已绑定的设备
        if (this.setDeviceId.length) {
          // 当次选中的是否为已绑定设备
          let a = false;
          // 循环判断当前选中设备是否已被绑定
          this.setDeviceId.forEach(item => {
            if (item === this.from.deviceId) {
              a = true;
              this.$message.warning('该设备已绑定，不可重复绑定');
            }
          });
          // 表示为true做出提示并退出
          if (a) {
            this.from.deviceId = '';
            this.isSet = false;
            return false;
          }
        }
        // 循环获取当前选中的设备信息 附加到node节点上
        this.DeviceData.forEach(item => {
          if (item.deviceId === this.from.deviceId) {
            this.node.deviceId = item.deviceId;
            this.node.label = item.deviceName;
            this.setDeviceId.push(item.deviceId);
          }
        });
        // 置空选择器
        this.from.deviceId = '';
        this.isSet = false;
        return false;
      }
      // 提示框
      switch (this.type) {
        //  删除已绑定设备，同时调用方法删除node
        case 'query':
          this.setDeviceId.forEach((item, index) => {
            if (item === this.node.deviceId) {
              this.setDeviceId.splice(index, 1, 0);
            }
          });
          event.del(this.graph, this.node);
          break;
        case 'queryDevice':
          // 仅解绑设备 清空node信息
          this.setDeviceId.forEach((item, index) => {
            if (item === this.node.deviceId) {
              this.setDeviceId.splice(index, 1, 0);
            }
          });
          this.node.deviceId = '';
          this.node.label = '';
          break;
      }
    },
    UpdateDevice() {
      this.DialogTitle = '换绑设备';
      this.from.deviceId = this.node.deviceId;
      this.isSet = true;
      this.$nextTick(() => {
        this.$refs.SetDialog.show();
      });
    },
    removeDevice() {
      this.DialogTitle = '解绑';
      this.type = 'queryDevice';
      this.content = '确定解绑已选设备吗?';
      this.$nextTick(() => {
        this.$refs.Dialog.show();
      });
    },
    removeNode() {
      this.DialogTitle = '删除';
      this.type = 'query';
      this.content = '删除标注区域将解绑此设备';
      this.$nextTick(() => {
        this.$refs.Dialog.show();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
/deep/ .el-form-item__label {
  color: #fff;
  font-size: 16px;
  font-family: stand;
}
/deep/ .el-input__inner {
  width: 340px;
  font-family: stand;
  color: #fff;
  border: 1px solid #1a868d;
  background-color: #192d36;
}
/deep/ .el-select .el-input__inner:focus {
  border: 1px solid #1a868d;
}
/deep/.el-select-dropdown {
  background-color: #192d36 !important;
}
/deep/ .x6-node text {
  fill: #fff !important;
}
/deep/ .x6-node rect {
  stroke: #fff !important;
}
</style>
