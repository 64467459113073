<template>
  <div class="Mapmain" :style="`background:${backgroundColor}`">
    <!-- <Mapheader :isMapShow="true" /> -->
    <Mapheader :btnGroup="btnGroup" :MapData="MapData" />
    <Graph :menushow="ismenu" ref="graph" :MapData="MapData" />
    <Dialog :title="DialogTitle" ref="Dialog" @confirm="confirm" :type="type" :content="content" />
    <Upload ref="Modelupload" :pattern="pattern" :accept="accept" @refresh="init" :title="'上传场景文件'" />
  </div>
</template>

<script>
import Mapheader from '../components/header';
import Graph from '../components/graph';
import Dialog from '../components/dialog';
import Upload from '../components/upload';
import Base64 from '../base64.js';
export default {
  components: {
    Mapheader,
    Graph,
    Dialog,
    Upload
  },
  data() {
    return {
      ismenu: null,
      DialogTitle: '',
      type: '',
      content: '',
      btnGroup: [
        {
          icon: 'iconfont icon-dcicon_save1',
          name: '保存',
          event: this.save
        },
        {
          icon: 'iconfont icon-dckuangxuan',
          name: '标注区域',
          event: this.Addlabel
        },
        {
          icon: 'iconfont icon-dcshuaxin',
          name: '重置',
          event: this.reset
        },
        {
          icon: 'iconfont icon-dcshangchuanmoxingwenjian',
          name: '上传场景文件',
          event: this.updateImg
        }
      ],
      MapData: null,
      // 模型==0 材质==1 背景文件==2
      pattern: 0,
      // 文件类型 模型==3ds 材质/背景===jpg/png
      accept: '',
      backgroundColor: ''
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const tenantId = JSON.parse(localStorage.getItem('user')).tenantId;
      const res = await this.$apis.Map3d.get({ tenantId });
      this.MapData = res;
      this.backgroundColor = this.MapData.backgrColor;
    },
    async save() {
      if (!this.MapData.backgrAddress) {
        this.$message.warning('未上传场景文件，无法保存标注');
        return false;
      }
      // Node位置信息
      const Node = this.$refs.graph.graph.toJSON().cells;
      // Node详细信息不带坐标位置
      const NodeDetail = this.$refs.graph.graph.getNodes();
      let bindDevices = [];
      // 循环添加设备Id
      Node.forEach(item => {
        NodeDetail.forEach(ele => {
          if (item.id === ele.id) {
            item.deviceId = ele.deviceId;
            bindDevices.push(ele.deviceId);
          }
        });
      });
      let isNull = false;
      Node.forEach(item => {
        if (!item.deviceId) {
          isNull = true;
        }
      });
      if (isNull) {
        this.$message.warning('有未绑定设备的标注区域,无法保存');
        return false;
      }
      // base64加码
      const mapContent = Base64.encode(JSON.stringify(Node));
      const tenantId = JSON.parse(localStorage.getItem('user')).tenantId;
      const { fail } = await this.$apis.Map3d.tagChange({ mapContent, tenantId, bindDevices });
      if (!fail) {
        return false;
      }
    },
    Addlabel() {
      this.$refs.graph.addNode();
    },
    reset() {
      this.DialogTitle = '重置';
      this.type = 'warning';
      this.content = '重置将删除场景、所有标注、设备绑定信息。请谨慎操作！';
      this.$refs.Dialog.show();
    },
    // 重置按钮确定
    async confirm() {
      const tenantId = JSON.parse(localStorage.getItem('user')).tenantId;
      const { fail } = await this.$apis.Map3d.reset({ tenantId });
      if (fail) {
        return false;
      }
      this.init();
      this.$refs.graph.clearAll();
    },
    updateImg() {
      this.pattern = 2;
      this.accept = '.png,.jpg';
      this.$refs.Modelupload.show();
    }
  }
};
</script>
<style lang="scss">
.Mapmain {
  width: 100vw;
  height: 100vh;
  background-color: #0a161b;
}
</style>
