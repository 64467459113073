<template>
  <el-dialog :title="title" :visible.sync="isupload" width="405px" :before-close="handleClose">
    <el-upload drag :accept="accept" action="" :file-list="fileList" :on-change="changeFile" :auto-upload="false" :on-remove="handleRemove" :multiple="false" :limit="2">
      <div class="uploadModel">拖拽到本区域上传</div>
      <div class="uploadBtn el-upload__text">或者 <span class="text">点击这里</span> 上传</div>
      <div class="uploadText el-upload__tip">{{ `文件类型${accept}仅限格式` }}</div>
    </el-upload>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click.stop="uploadFile" size="mini" :disabled="fileList.length === 0 ? true : false">上传</el-button>
      <el-button @click="cloceUploadFile" size="mini">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: ['accept', 'pattern', 'title'],
  data() {
    return {
      isupload: false,
      fileList: []
    };
  },
  methods: {
    // 展示
    show() {
      this.isupload = true;
    },
    // 更改文件
    changeFile(file) {
      this.fileList = [];
      switch (this.accept) {
        case '.obj':
          if (file.name.indexOf('.obj') !== -1) {
            this.fileList.push(file);
          } else {
            this.$message.warning('文件格式需为obj');
          }
          break;
        case '.png,.jpg':
          if (file.name.indexOf('.jpg') !== -1 || file.name.indexOf('.png') !== -1) {
            this.fileList.push(file);
          } else {
            this.$message.warning('文件格式需为png或jpg');
          }
          break;
        case '.mtl':
          if (file.name.indexOf('.mtl') !== -1) {
            this.fileList.push(file);
          } else {
            this.$message.warning('文件格式需为mtl');
          }
          break;
      }
    },
    // 删除文件
    handleRemove() {
      this.fileList = [];
    },
    // 关闭弹窗
    cloceUploadFile() {
      this.fileList = [];
      this.isupload = false;
    },
    // 确定上传
    async uploadFile() {
      const loading = this.$loading({
        lock: true,
        text: '上传中请稍候(文件较大用时较长)',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const file = this.fileList[0].raw;
      const { fail } = await this.$apis.Map3d.upload({ pattern: this.pattern, file });
      loading.close();
      if (fail) {
        return false;
      }
      this.$emit('refresh');
      this.fileList = [];
      this.isupload = false;
    },
    handleClose() {
      this.fileList = [];
      this.isupload = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.uploadModel {
  font-weight: bolder;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 40px;
  color: #fff;
}
.uploadBtn {
  color: #fff;
  .text {
    color: #29f5ff;
  }
}
.uploadText {
  color: #fff;
}
/deep/.el-dialog__body,
/deep/.el-dialog__header,
/deep/.el-dialog__footer {
  background-color: #0b151d;
  color: #fff;
}
/deep/ .el-dialog__title {
  color: #fff;
}
/deep/ .el-upload-dragger {
  background-color: #0b151d;
  border: 1px solid #3e6a72;
}
/deep/ .el-button--primary {
  background-color: rgba($color: #1b858f, $alpha: 0.5) !important;
  border: 1px solid #29f5ff;
}
/deep/ .el-button--default {
  color: #bdbdbd;
  background-color: #0a161b !important;
  border: 1px solid #bdbdbd;
}
/deep/ .el-upload-list__item-name {
  color: #fff !important;
}
/deep/ .el-upload-list__item:hover {
  background-color: #29f5ff;
}
/deep/ .el-upload:focus {
  border-color: #29f5ff;
  color: #29f5ff;
}
.el-upload:focus .el-upload-dragger {
  border-color: #29f5ff;
}
</style>
