import rightmenu from './ContextMenu/index.vue';
import '@antv/x6-vue-shape';
export default {
  // 右击Node节点
  rightClick: entity => {
    entity.graph.on('node:contextmenu', ({ node, x, y }) => {
      if (node.deviceId) {
        entity.isupdate = true;
      } else {
        entity.isupdate = false;
      }
      entity.x = x;
      entity.y = y;
      entity.node = node;
      entity.components = { rightmenu };
      entity.ismenu = true;
    });
  },
  // 移除菜单
  removeMenu: entity => {
    entity.graph.on('node:click', () => {
      entity.ismenu = false;
    });
    entity.graph.on('blank:contextmenu', () => {
      entity.ismenu = false;
    });
    entity.graph.on('blank:click', () => {
      entity.ismenu = false;
    });
  },
  // 撤销 ctrl+z
  z: graph => {
    graph.bindKey('ctrl+z', () => {
      graph.history.undo();
      return false;
    });
  },
  // 删除节点
  del: (graph, node) => {
    graph.removeNode(node);
  },
  // 添加节点
  add: graph => {
    graph.addNode({
      shape: 'rect', // 指定使用何种图形值为 'rect' (矩形)
      x: 100,
      y: 200,
      width: 80,
      height: 40,
      attrs: {
        body: {
          fill: 'rgba(255, 0, 0,.4)', // 背景颜色
          stroke: '#000'
        }
      }
    });
  }
};
