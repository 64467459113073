<template>
  <div
    class="BOX"
    ref="box"
    @click="
      () => {
        this.$refs.box.style.display = 'none';
        this.$parent.ismenu = false;
      }
    "
  >
    <div class="btnGroup" :style="`top:${y + 156}px;left:${x}px`">
      <div class="btn" v-if="!isupdate" @click="$emit('SetDevice')">绑定设备</div>
      <div class="btn" v-if="isupdate" @click="$emit('removeDevice')">解绑设备</div>
      <div class="btn" v-if="isupdate" @click="$emit('UpdateDevice')">换绑设备</div>
      <div class="btn" @click="$emit('removeNode')">删除标注</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'rightmenu',
  props: ['x', 'y', 'isupdate'],
  data() {
    return {
      num: 0
    };
  },
  methods: {}
};
</script>
<style lang="scss" scoped>
.BOX {
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  .btnGroup {
    position: absolute;
    .btn {
      font-family: stand;
      width: 90px;
      height: 35px;
      line-height: 35px;
      background-color: #fff;
      text-align: center;
      cursor: pointer;
      border: 1px solid white;
      // background-color: aliceblue;
      &:hover {
        background-color: #dedbdc;
      }
    }
  }
}
</style>
